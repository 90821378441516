import React from "react";
import {
  FormGroup,
  FormLabel,
  TextField,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UploadImage from "../UploadImage";
import { Formik } from "formik";
import { IoMdClose } from "react-icons/io";
import moment from "moment";

export default function StoreForm({ value, onSubmit }) {
  const theme = useTheme();
  return (
    <Formik
      initialValues={{
        ...value,
        image: value?.image || "",
        name: value?.name || "",
        address: value?.address || "",
        status: value?.status || "WAITING",
        categoryStore: value?.categoryStore || "NORMAL",
        contacts: value?.contacts || {},
        rental: value?.rental || {},
        status: value?.status || "WAITING",
      }}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          await onSubmit(values);
          setSubmitting(false);
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <UploadImage
                src={values?.image}
                onChange={(e) => setFieldValue("image", e?.name)}
              />
            </div>
            <FormGroup>
              <FormLabel>ຊື່ຮ້ານ</FormLabel>
              <TextField
                error={errors.name}
                placeholder="ຊື່ຮ້ານ"
                name="name"
                value={values?.name}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ທີ່ຢູ່ຮ້ານ</FormLabel>
              <TextField
                error={errors.address}
                name="address"
                value={values?.address}
                onChange={handleChange}
                placeholder="ທີ່ຢູ່ຮ້ານ..."
                multiline
                InputProps={{ style: { minHeight: 100, alignItems: "start" } }}
                size="small"
              />
            </FormGroup>
            <FormControl fullWidth size="small">
              <InputLabel id="status">ສະຖານະ</InputLabel>
              <Select
                labelId="status"
                error={errors.status}
                value={values.status}
                name="status"
                label="ສະຖານະ"
                onChange={handleChange}
              >
                <MenuItem value={"WAITING"}>ລໍຖ້າການຍືນຍັນ</MenuItem>
                <MenuItem value={"REJECTED"}>ຖືກປະຕິເສດ</MenuItem>
                <MenuItem value={"APPROVED"}>ຍືນຍັນແລ້ວ</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="categoryStore">ປະເພດຮ້ານ</InputLabel>
              <Select
                labelId="categoryStore"
                error={errors.categoryStore}
                value={values.categoryStore}
                name="categoryStore"
                label="ປະເພດຮ້ານ"
                onChange={handleChange}
              >
                <MenuItem value={"NORMAL"}>ຮ້ານທົ່ວໄປ</MenuItem>
              </Select>
            </FormControl>
            <div>ຂໍ້ມູນຕິດຕໍ່</div>
            <FormGroup>
              <FormLabel>Whatsapp</FormLabel>
              <TextField
                error={errors.contacts?.whatsapp}
                placeholder="whatsapp"
                name="contacts.whatsapp"
                value={values?.contacts?.whatsapp}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Phone</FormLabel>
              <TextField
                error={errors.contacts?.phone}
                placeholder="phone"
                name="contacts.phone"
                value={values?.contacts?.phone}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Facebook</FormLabel>
              <TextField
                error={errors.contacts?.fb}
                placeholder="fb"
                name="contacts.fb"
                value={values?.contacts?.fb}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <Box sx={{ border: "1px dotted #ccc", p: 1, borderRadius: "8px" }}>
              <div>ວັນທີເຊົ່າ</div>
              <FormGroup>
                <FormLabel>ເລີ່ມເຊົ່າ</FormLabel>
                <TextField
                  type="date"
                  error={errors.rental?.start}
                  placeholder="start"
                  name="rental.start"
                  value={moment(values?.rental?.start).format("YYYY-MM-DD")}
                  onChange={handleChange}
                  size="small"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>ເຊົ່າຮອດວັນທີ</FormLabel>
                <TextField
                  type="date"
                  error={errors.rental?.end}
                  placeholder="end"
                  name="rental.end"
                  value={moment(values?.rental?.end).format("YYYY-MM-DD")}
                  onChange={handleChange}
                  size="small"
                />
              </FormGroup>
            </Box>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ boxShadow: 0 }}
              size="large"
            >
              ບັນທຶກ
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
