import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import { IoStorefront } from "react-icons/io5";
import { STORE_PAGE, USER_PAGE } from "../../constants/routes";
import { _Store, _StoreDelete } from "../../apis";
import { URL_PHOTO_AW3 } from "../../constants";
import imagePlaceholder from "../../images/image-placeholder.jpeg";
import DeleteStorePopup from "../../components/popup/DeleteStorePopup";
import { toast } from "react-toastify";
import { _Users } from "../../apis/Users.service";

export default function StoreDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  // state
  const [storeData, setStoreData] = useState();
  const [usersData, setUsersData] = useState();
  const [popup, setPopup] = useState({ delete: false });

  const handleDeleteStore = async () => {
    setPopup({ delete: false });
    const toastId = toast.loading("ກຳລັງລົບຮ້ານ...");
    const data = await _StoreDelete(params?.storeId);
    if (data?.err) {
      toast.update(toastId, {
        render: "ບໍ່ສຳເລັດ",
        type: "error",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render: `ລົບຮ້ານ ${storeData?.name} ສຳເລັດ`,
        type: "success",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
      navigate("/" + STORE_PAGE);
    }
  };

  useEffect(() => {
    (async () => {
      const data = await _Store(params?.storeId);
      if (!data?.err) {
        setStoreData(data?.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let findby = "?";
      findby += `storeId=${params?.storeId}`;
      const data = await _Users(findby);
      console.log("data", data);
      if (!data?.err) {
        setUsersData(data?.data);
      }
    })();
  }, []);


  const onViewStore = () => {
    window.open(`https://shop.unitechlao.com/store/view-store/limit/50/skip/1/storeId/`+params?.storeId)
  }


  return (
    <>
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            color="inherit"
            onClick={() => navigate("/" + STORE_PAGE)}
          >
            <IoStorefront style={{ marginRight: 5 }} />
            ຈັດການຮ້ານ
          </Typography>

          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            color="text.primary"
          >
            {/* <GroupAddIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            ລາຍລະອຽດຮ້ານ
          </Typography>
        </Breadcrumbs>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div>ລາຍລະອຽດຮ້ານ</div>
          <div style={{ display: "flex", gap: 10 }}>
          <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: 5,
                borderRadius: 4,
                color: "white",
              }}
              onClick={() => onViewStore()}
            >
              ລາຍລະອຽດໜ້າຮ້ານ
            </div>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: 5,
                borderRadius: 4,
                color: "white",
              }}
              onClick={() =>
                navigate(`/${STORE_PAGE}/update/${params?.storeId}`)
              }
            >
              ແກ້ໄຂຮ້ານ
            </div>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: 5,
                borderRadius: 4,
                color: "white",
              }}
              onClick={() => setPopup({ delete: true })}
            >
              ລົບຮ້ານ
            </div>
          </div>
        </div>
        <div>
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                width: "100%",
                maxWidth: 600,
                height: 150,
                borderRadius: 8,
                overflow: "hidden",
                marginBottom: 10,
              }}
            >
              <img
                src={
                  storeData?.image
                    ? URL_PHOTO_AW3 + "/" + storeData?.image
                    : imagePlaceholder
                }
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <div>ຊື່ຮ້ານ: {storeData?.name}</div>
              <div>ທີ່ຢູ່ຮ້ານ: {storeData?.address}</div>
              <div>id ຮ້ານທີ່ເຊີນ: {storeData?.inviter}</div>
              <div>ສະຖານະຮ້ານ: {storeData?.status}</div>
              <div>ປະເພດຮ້ານ: {storeData?.categoryStore}</div>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  padding: 10,
                  // border: "1px dotted black",
                  borderRadius: 8,
                  backgroundColor: "rgb(245,245,245)",
                }}
              >
                <div>ຊ່ອງທາງຕິດຕໍ່:</div>
                <div>
                  <div>whatsapp: {storeData?.contacts?.whatsapp}</div>
                  <div>phone: {storeData?.contacts?.phone}</div>
                  <div>line: {storeData?.contacts?.line}</div>
                  <div>fb: {storeData?.contacts?.fb}</div>
                  <div>page_fb: {storeData?.contacts?.page_fb}</div>
                  <div>ticktok: {storeData?.contacts?.ticktok}</div>
                  <div>wechat: {storeData?.contacts?.wechat}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: 10,
                color: "white",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>ຜູ້ໃຊ້</div>
              <div
                style={{
                  backgroundColor: theme.palette.primary.dark,
                  padding: 5,
                  borderRadius: 4,
                }}
                onClick={() =>
                  navigate(`/${USER_PAGE}/create/${params?.storeId}`)
                }
              >
                ເພີ່ມຜູ້ໃຊ້
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {usersData?.map((e, i) => (
                <div
                  style={{
                    backgroundColor: "rgb(240,240,240)",
                    padding: 5,
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                  key={i}
                  onClick={() => navigate(`/${USER_PAGE}/detail/${e?._id}`)}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 50,
                      height: 50,
                      borderRadius: 8,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={
                        e?.image
                          ? URL_PHOTO_AW3 + "/" + e?.image
                          : imagePlaceholder
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div>
                    {e?.username} - ({e?.name}){" "}
                    <span style={{ color: "red" }}>
                      {e?.role === "STORE" ? "ເຈົ້າຂອງຮ້ານ" : ""}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <DeleteStorePopup
        open={popup?.delete}
        text={storeData?.name}
        onClose={() => setPopup((prev) => ({ ...prev, delete: false }))}
        onSubmit={handleDeleteStore}
      />
    </>
  );
}
