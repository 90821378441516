import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import { IoStorefront } from "react-icons/io5";
import { STORE_PAGE, USER_PAGE } from "../../constants/routes";
import { _Store, _StoreDelete } from "../../apis";
import { URL_PHOTO_AW3 } from "../../constants";
import imagePlaceholder from "../../images/image-placeholder.jpeg";
import DeleteStorePopup from "../../components/popup/DeleteStorePopup";
import { toast } from "react-toastify";
import { _User, _UserDelete, _Users } from "../../apis/Users.service";
import DeleteUserPopup from "../../components/popup/DeleteUserPopup";

export default function UserDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  // state
  const [storeData, setStoreData] = useState();
  const [userData, setUserData] = useState();
  const [popup, setPopup] = useState({ delete: false });

  const handleDeleteUser = async () => {
    setPopup({ delete: false });
    const toastId = toast.loading("ກຳລັງລົບຮ້ານ...");
    const data = await _UserDelete(params?.userId);
    if (data?.err) {
      toast.update(toastId, {
        render: "ບໍ່ສຳເລັດ",
        type: "error",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render: `ລົບຜູ້ໃຊ້ ${storeData?.name} ສຳເລັດ`,
        type: "success",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
      navigate("/" + STORE_PAGE + "/detail/" + storeData?._id);
    }
  };

  useEffect(() => {
    (async () => {
      const data = await _Store(userData?.storeId);
      if (!data?.err) {
        setStoreData(data?.data);
      }
    })();
  }, [userData]);

  useEffect(() => {
    (async () => {
      const data = await _User(params?.userId);
      console.log("data", data);
      if (!data?.err) {
        setUserData(data?.data);
      }
    })();
  }, []);

  return (
    <>
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            color="inherit"
            onClick={() => navigate("/" + STORE_PAGE)}
          >
            <IoStorefront style={{ marginRight: 5 }} />
            ຈັດການຮ້ານ
          </Typography>

          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            color="text.primary"
          >
            {/* <GroupAddIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            ລາຍລະອຽດຜູ້ໃຊ້
          </Typography>
        </Breadcrumbs>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div>ລາຍລະອຽດຜູ້ໃຊ້</div>
          <div style={{ display: "flex", gap: 10 }}>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: 5,
                borderRadius: 4,
                color: "white",
              }}
              onClick={() =>
                navigate(`/${USER_PAGE}/update/${params?.userId}`)
              }
            >
              ແກ້ໄຂຜູ້ໃຊ້
            </div>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: 5,
                borderRadius: 4,
                color: "white",
              }}
              onClick={() => setPopup({ delete: true })}
            >
              ລົບຜູ້ໃຊ້
            </div>
          </div>
        </div>
        <div>
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                width: "100%",
                maxWidth: 150,
                height: 150,
                borderRadius: 8,
                overflow: "hidden",
                marginBottom: 10,
              }}
            >
              <img
                src={
                  userData?.image
                    ? URL_PHOTO_AW3 + "/" + userData?.image
                    : imagePlaceholder
                }
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <div>ຊື່: {userData?.name}</div>
              <div>Username: {userData?.username}</div>
              <div>
                ສະຖານະ:{" "}
                {userData?.status === "ACTIVE" ? "ໃຊ້ງານໄດ້" : "ຖືກບລ໊ອກ"}
              </div>
              <div>ຮ້ານ: {storeData?.name}</div>
              <div>
                ສິດການນຳໃຊ້: {userData?.role === "STORE" ? "ເຈົ້າຂອງຮ້ານ" : "ພະນັກງານ"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteUserPopup
        open={popup?.delete}
        text={userData?.name}
        onClose={() => setPopup((prev) => ({ ...prev, delete: false }))}
        onSubmit={handleDeleteUser}
      />
    </>
  );
}
