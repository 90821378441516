import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

//component
import UploadImage from "../../components/UploadImage";


//mui
import { Dialog, DialogActions, DialogContent, Switch, FormGroup, FormControlLabel, DialogTitle, Breadcrumbs, Link, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
//icon
import { IoStorefront } from "react-icons/io5";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

function AdsList() {
    const [openForm, setOpenForm] = useState(false);
    const [isShow, setIsShow] = useState(false)
    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];


    const handleClickOpen = () => {
        setOpenForm(true);
    };

    const handleClose = () => {
        setOpenForm(false);
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center" }}
                    color="inherit"
                //   href="#"
                //   onClick={() => navigate(-1)}
                >
                    <IoStorefront style={{ marginRight: 5 }} />
                    ຈັດການໂຄສະນາ
                </Link>
            </Breadcrumbs>
            <div style={{ height: '20px' }}></div>
            <h2>ລາຍການໂຄສະນາ</h2>

            {/* button add */}
            <div style={{ marginBottom: 20, display: 'flex', justifyContent: "end" }}>
                <Button variant="contained" onClick={handleClickOpen}>ເພີ່ມໂຄສະນາ</Button>
            </div>

            {/* button add */}

            {/* table list */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Dessert (100g serving)</TableCell>
                            <TableCell align="right">Calories</TableCell>
                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.calories}</TableCell>
                                <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {/* table list */}

            <Formik
                initialValues={{
                    image:"",
                }}
                validate={(values) => {
                    const errors = {};

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    (async () => {
                        let _data ={
                            ...values,
                            status:isShow,
                            isPublished: true,
                        }
                        
                        console.log("_data::",_data)
                        // await onSubmit(values);
                        setSubmitting(false);
                    })();
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <Dialog
                        open={openForm}
                        onClose={handleClose}
                        fullWidth={true}
                        maxWidth={"sm"}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ຟອມເພີ່ມໂຄສະນາ"}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        gap: 10,
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <UploadImage
                                            style={{width: "100%"}}
                                            src={values?.image}
                                            onChange={(e) => setFieldValue("image", e?.name)}
                                        />
                                    </div>
                                    <center>
                                        <FormControlLabel control={<Switch defaultChecked={isShow} />} onChange={() => setIsShow(!isShow)} label="ເປີດນຳໃຊ້" />
                                    </center>
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>ຍົກເລີກ</Button>
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    onClick={handleSubmit}
                                    autoFocus>
                                    ບັນທຶກ
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                )}
            </Formik>
        </div>
    )
}

export default AdsList