import React from 'react'
import { Formik } from "formik";

//css
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};



const UserAdd = ({ handleClose, open }) => {
    return (
        <Formik
            initialValues={{
                name:  "",
                username: "", 
                password:"",
                status:"",
                role:"ADMIN"
            }}
            validate={(values) => {
                const errors = {};
                if(!values.name) errors.name="ກະລຸນາປ້ອນຊື່"
                if(!values.username) errors.username="ກະລຸນາປ້ອນຊື່ເຂົ້າລະບົບ"
                if(!values.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ"
                if(!values.status) errors.status = "ກະລຸນາເລືອກສະຖານະນຳໃຊ້"
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log("values::", values)
                // (async () => {
                //     await onSubmit(values);
                //     setSubmitting(false);
                // })();
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                ເພີ່ມຜູ້ນຳໃຊ້ລະບົບ
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Grid container>
                                    <Grid md="12" style={{ marginBottom: 20 }}>
                                        <TextField fullWidth id="outlined-basic" 
                                         error={errors.name}
                                        onChange={handleChange} name="name" value={values.name} label="ຊື່ ແລະ ນາມສະກຸນ" variant="outlined" color='success' />
                                    </Grid>
                                    <Grid md="12" style={{ marginBottom: 20 }}>
                                        <TextField fullWidth id="outlined-basic" error={errors.username} onChange={handleChange} name="username" value={values.username} label="ຊື່ນຳໃຊ້ເຂົ້າລະບົບ" variant="outlined" color='success' />
                                    </Grid>
                                    <Grid md="12" style={{ marginBottom: 20 }}>
                                        <TextField fullWidth id="outlined-basic" error={errors.password}  onChange={handleChange} label="ລະຫັດຜ່ານ" name='password' value={values.password} variant="outlined" color='success' />
                                    </Grid>
                                    <Grid md="12" style={{ marginBottom: 20 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">ສະຖານະ</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                error={errors.status}
                                                id="demo-simple-select"
                                                value={values.status}
                                                name='status'
                                                label="ສະຖານະ"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"ACTIVE"}>ເປີດນຳໃຊ້</MenuItem>
                                                <MenuItem value={"BLOCKED"}>ປິດນຳໃຊ້</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                </Grid>
                            </Typography>
                            <Typography>
                                <Stack spacing={2} direction="row">
                                    <Button type="submit" variant="contained" onClick={handleSubmit} color="success">ບັນທຶກ</Button>
                                    <Button variant="outlined" color="error">ຍົກເລີກ</Button>
                                </Stack>
                            </Typography>
                        </Box>
                    </Modal>
                </form>

            )}
        </Formik>
    )
}

export default UserAdd