import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { IoStorefront } from "react-icons/io5";
import { STORE_PAGE } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import StoreForm from "../../components/form/StoreForm";
import { _StoreCreate } from "../../apis";
import { toast } from "react-toastify";

export default function StoreAddPage() {
  const navigate = useNavigate();
  const handleCreateStore = async (value) => {
    const toastId = toast.loading("ກຳລັງບັນທຶກຮ້ານ...");
    const data = await _StoreCreate(value);
    if (data?.err) {
      toast.update(toastId, {
        render: "ບໍ່ສຳເລັດ",
        type: "error",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render: `ເພີ່ມຮ້ານ ${data?.data?.name} ສຳເລັດ`,
        type: "success",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
      navigate("/" + STORE_PAGE);
    }
  };
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          color="inherit"
          onClick={() => navigate("/" + STORE_PAGE)}
        >
          <IoStorefront style={{ marginRight: 5 }} />
          ຈັດການຮ້ານ
        </Typography>

        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          color="text.primary"
        >
          {/* <GroupAddIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
          ເພີ່ມຮ້ານ
        </Typography>
      </Breadcrumbs>
      <div>ເພີ່ມຮ້ານ</div>
      <div>
        <StoreForm onSubmit={handleCreateStore} />
      </div>
    </div>
  );
}
