import React from "react";
import { FormGroup, FormLabel, TextField, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UploadImage from "../UploadImage";
import { Formik } from "formik";
import { IoMdClose } from "react-icons/io";

export default function UserForm({ value, onSubmit }) {
  const theme = useTheme();
  return (
    <Formik
      initialValues={{
        storeId: value?.storeId || "",
        name: value?.name || "",
        type: value?.type || "",
        detial: value?.detial || "",
        price: value?.price || "",
        option: value?.option || [],
      }}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          await onSubmit(values);
          setSubmitting(false);
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <UploadImage />
            </div>
            <FormGroup>
              <FormLabel>ຊື່ສິນຄ້າ</FormLabel>
              <TextField
                error={errors.name}
                placeholder="ຊື່ສິນຄ້າ"
                name="name"
                value={values?.name}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ປະເພດ</FormLabel>
              <TextField
                error={errors.type}
                placeholder="ປະເພດສິນຄ້າ"
                name="type"
                value={values?.type}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ລາຍລະອຽດສິນຄ້າ</FormLabel>
              <TextField
                error={errors.detial}
                name="detial"
                value={values?.detial}
                onChange={handleChange}
                placeholder="ລາຍລະອຽດສິນຄ້າ..."
                multiline
                InputProps={{ style: { minHeight: 100, alignItems: "start" } }}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ລາຄາ</FormLabel>
              <TextField
                error={errors.price}
                name="price"
                type="number"
                value={values?.price}
                onChange={handleChange}
                placeholder="ລາຄາ..."
                size="small"
              />
            </FormGroup>
            <Box sx={{ border: "1px dotted #ccc", p: 1, borderRadius: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "5px",
                }}
              >
                <span>Option ສິນຄ້າ</span>
                <span
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  ເພີ່ມ
                </span>
              </Box>
              <Box
                sx={{ border: "1px dotted #ccc", p: 1, borderRadius: "8px" }}
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    error={errors.price}
                    name="price"
                    type="number"
                    value={values?.price}
                    onChange={handleChange}
                    placeholder="ລາຄາ..."
                    size="small"
                    fullWidth
                  />
                  <TextField
                    error={errors.price}
                    name="price"
                    type="number"
                    value={values?.price}
                    onChange={handleChange}
                    placeholder="ລາຄາ..."
                    size="small"
                    fullWidth
                  />
                  <Button variant="contained" sx={{ boxShadow: 0 }}>
                    <IoMdClose />
                  </Button>
                </Box>
              </Box>
            </Box>
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ boxShadow: 0 }}
              size="large"
            >
              ບັນທຶກ
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
