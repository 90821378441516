import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { AppBar, Toolbar, Typography, Box, Drawer,Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { CgMenu } from "react-icons/cg";
import { IoHome, IoStorefront } from "react-icons/io5";
import { FiChevronDown } from "react-icons/fi";
import { SiGoogleads } from "react-icons/si";
import { HiOutlineClipboardList } from "react-icons/hi";
import { FaBoxOpen } from "react-icons/fa";
import { BiBox } from "react-icons/bi";
import { RiSettings3Fill } from "react-icons/ri";

import Routes from "../constants/routes";

import Hidden from "../components/Hidden";

export default function PrivateLayout() {
  const [drawerSide, setDrawerSide] = useState(false);

  const navigate = useNavigate();
  const logOut =async () => {
    await localStorage.clear("token")
    await localStorage.clear("store")
    await navigate('/login')
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", md: "260px 1fr" },
        height: "100vh",
      }}
    >
      <Hidden width="mdDown">
        <SideComponent />
      </Hidden>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <Hidden width="mdUp">
              <CgMenu
                style={{ width: 30, height: 30 }}
                onClick={() => setDrawerSide(true)}
              />
              <Drawer
                anchor="left"
                open={drawerSide}
                onClose={() => setDrawerSide(false)}
              >
                <SideComponent />
              </Drawer>
            </Hidden>
            <Button color="inherit" onClick={() => logOut()} >Logout</Button>
          </Toolbar>


        </AppBar>

        <Box sx={{ p: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

const SideComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AppBar position="static" sx={{ zIndex: 100, boxShadow: 1 }}>
        <Toolbar>
          <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
            Admin
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          {/* <CgMenu style={{ width: 30, height: 30 }} /> */}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          boxShadow: 1,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {[
          // {
          //   icon: <IoHome style={{ width: 20, height: 20 }} />,
          //   title: "ໜ້າຫຼັກ",
          // },
          {
            icon: <IoStorefront style={{ width: 20, height: 20 }} />,
            title: "ຈັດການຮ້ານ",
            link: Routes.STORE_PAGE,
          },
          {
            icon: <SiGoogleads style={{ width: 20, height: 20 }} />,
            title: "ຈັດການໂຄສະນາ",
            link: Routes.ADS_PAGE,

          },
          // {
          //   icon: <HiOutlineClipboardList style={{ width: 20, height: 20 }} />,
          //   title: "ຈັດການປະເພດສິນຄ້າ",
          // },
          // {
          //   icon: <FaBoxOpen style={{ width: 20, height: 20 }} />,
          //   title: "ຈັດການສິນຄ້າ",
          // },
          // {
          //   icon: <BiBox style={{ width: 20, height: 20 }} />,
          //   title: "ຈັດການ option",
          // },
          {
            icon: <RiSettings3Fill style={{ width: 20, height: 20 }} />,
            link: `${Routes.SETTING_PAGE}`,
            title: "ຕັ້ງຄ່າຮ້ານ",
          },
        ].map((e, i) => (
          <Box
            onClick={() => navigate(e?.link)}
            sx={{
              width: "100%",
              p: 2,
              display: "flex",
              alignItems: "center",
              gap: 1,
              ":hover": {
                backgroundColor: "#EDEDED",
                cursor: "pointer",
              },
              color: theme.palette.primary.dark,
            }}
            key={i}
          >
            {e?.icon}
            <Box sx={{ fontSize: 16, fontWeight: "bold" }}>{e?.title}</Box>
            <Box sx={{ flexGrow: 1 }} />
            <FiChevronDown />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
