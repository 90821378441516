import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { IoStorefront } from "react-icons/io5";
import { STORE_PAGE } from "../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import StoreForm from "../../components/form/StoreForm";
import { _Store, _StoreCreate, _StoreUpdate } from "../../apis";
import { toast } from "react-toastify";

export default function StoreEditPage() {
  const params = useParams();
  const navigate = useNavigate();

  // state
  const [storeData, setStoreData] = useState();

  // function
  const handleUpdateStore = async (value) => {
    const toastId = toast.loading("ກຳລັງບັນທຶກຮ້ານ...");
    const data = await _StoreUpdate(params?.storeId, value);
    if (data?.err) {
      toast.update(toastId, {
        render: "ບໍ່ສຳເລັດ",
        type: "error",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render: `ແກ້ໄຂຮ້ານ ${data?.data?.name} ສຳເລັດ`,
        type: "success",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
      navigate("/" + STORE_PAGE);
    }
  };
  useEffect(() => {
    (async () => {
      const data = await _Store(params?.storeId);
      setStoreData(data?.data);
      console.log(data);
    })();
  }, []);
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          color="inherit"
          onClick={() => navigate("/" + STORE_PAGE)}
        >
          <IoStorefront style={{ marginRight: 5 }} />
          ຈັດການຮ້ານ
        </Typography>

        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          color="text.primary"
        >
          {/* <GroupAddIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
          ແກ້ໄຂຮ້ານ
        </Typography>
      </Breadcrumbs>
      <div>ແກ້ໄຂຮ້ານ</div>
      <div>
        {storeData && (
          <StoreForm onSubmit={handleUpdateStore} value={storeData} />
        )}
      </div>
    </div>
  );
}
