import { Outlet } from "react-router-dom";
import Routes from "../constants/routes";
import StoreAddPage from "../pages/store/StoreAddPage";
import StoreDetailPage from "../pages/store/StoreDetailPage";
import StoreEditPage from "../pages/store/StoreEditPage";
import StoreListPage from "../pages/store/StoreListPage";
import UserAddPage from "../pages/user/UserAddPage";
import UserDetailPage from "../pages/user/UserDetailPage";
import UserEditPage from "../pages/user/UserEditPage";

// eslint-disable-next-line
export default {
  path: Routes.USER_PAGE,
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <StoreListPage />,
    },
    {
      path: "detail/:userId",
      element: <UserDetailPage />,
    },
    {
      path: "create/:storeId",
      element: <UserAddPage />,
    },
    {
      path: "update/:userId",
      element: <UserEditPage />,
    },
  ],
};
