import { Outlet } from "react-router-dom";
import ProductsPage from "../pages/product/ProductsPage";

// eslint-disable-next-line
export default {
  path: "product",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <ProductsPage />,
    },
  ],
};
