import React, { useState } from "react";
import { Button } from "@mui/material";
import ProductTable from "../../components/table/ProductTable";
import AddUserPopup from "../../components/popup/AddUserPopup";
import { _ProductCreate } from "../../apis/Products.service";

export default function ProductsPage() {
  // state
  const [popAddUser, setPopAddUser] = useState(false);
  const [popEditUser, setPopEditUser] = useState(false);
  const [popDeleteUser, setPopDeleteUser] = useState(false);

  // functions
  const addProduction = async (e) => {
    console.log("data", e);
    const data = await _ProductCreate(e);
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            ຈັດການສິນຄ້າ
          </div>
          <Button
            size="large"
            variant="contained"
            onClick={() => setPopAddUser(true)}
          >
            ເພີ່ມສິນຄ້າ
          </Button>
        </div>
        <ProductTable />
      </div>
      {/* popup */}
      <AddUserPopup
        open={popAddUser}
        onClose={() => setPopAddUser(false)}
        onSubmit={addProduction}
      />
    </>
  );
}
