module.exports = {
  //setting
  SETTING_PAGE: "setting-page",

  //user
  USER_PAGE: "user",
  USER_LIST: "user-list",

  // store
  STORE_PAGE: "store",

  // ads
  ADS_PAGE:"ads-page",
};
