import React from "react";
import { Box } from "@mui/material";

export default function ProductTable() {
  return (
    <div>
      <table>
        <tr>
          <th>ລຳດັບ</th>
          <th>ລຳດັບ</th>
          <th>ລຳດັບ</th>
          <th>ລຳດັບ</th>
        </tr>
      </table>
    </div>
  );
}
