import axios from "axios";
import { BACKEND_ENPOINT } from "../constants";
// --------------------- //

const _StoresAPI = BACKEND_ENPOINT + "/stores";
const _StoreAPI = BACKEND_ENPOINT + "/store";
const _StoreCreateAPI = BACKEND_ENPOINT + "/store";
const _StoreUpdateAPI = BACKEND_ENPOINT + "/store";
const _StoreDeleteAPI = BACKEND_ENPOINT + "/store";

export async function _Stores() {
  try {
    const data = await axios.get(_StoresAPI);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Store(_id) {
  try {
    const data = await axios.get(_StoreAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StoreCreate(value) {
  try {
    const data = await axios.post(_StoreCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StoreUpdate(_id, value) {
  try {
    const data = await axios.put(_StoreUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StoreDelete(_id) {
  try {
    const data = await axios.delete(_StoreDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
