import React from 'react'
import { useNavigate } from "react-router-dom";

//constants
import Routes from "../../constants/routes"

//css
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CategoryIcon from '@mui/icons-material/Category';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import Link from '@mui/material/Link';
// import HomeIcon from '@mui/icons-material/Home';
// import WhatshotIcon from '@mui/icons-material/Whatshot';
// import GrainIcon from '@mui/icons-material/Grain';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    minHeight: 100,
    cursor: "pointer",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    color: theme.palette.text.secondary,
    ":hover": {
        backgroundColor: "lightgray"
    }
}));

function SettingPage() {
    //constants
    const navigate = useNavigate()


    const memuSetting = [
        {
            icon: <GroupAddIcon sx={{ mr: 0.5, fontSize: 40 }} />,
            menuName: "ຕັ້ງຄ່າຜູ້ນຳໃຊ້",
            link: Routes.USER_LIST
        },
        {
            icon: <CategoryIcon sx={{ mr: 0.5, fontSize: 40 }} />,
            menuName: "ຕັ້ງຄ່າໝວດປະເພດຮ້ານ",
            link: ""
        },
    ]


    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                {/* <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    // href="/"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    MUI
                </Link> */}

                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    <DisplaySettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    ຈັດການຕັ້ງຄ່າ
                </Typography>
            </Breadcrumbs>

            <Grid container spacing={2} style={{ marginTop: 10 }}>

                {memuSetting?.map((menu, index) =>
                    <Grid item md={3} key={"menu"+index} onClick={()=> navigate(menu.link)}>
                        <Item>
                            {menu?.icon}
                            {menu?.menuName}
                        </Item>
                    </Grid>
                )}
            </Grid>







        </>
    )
}

export default SettingPage