import { Outlet } from "react-router-dom";
import Routes from "../constants/routes"
import SettingPage from "../pages/setting/SettingPage";

//user
import UserPage from "../pages/setting/users/UserPage"


// eslint-disable-next-line
export default {
  path: Routes.SETTING_PAGE,
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <SettingPage />,
    },
    {
      path: Routes.USER_LIST ,
      element: <UserPage />,
    },
  ],
};
