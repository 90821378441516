import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import UserForm from "../form/ProductForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeleteStorePopup({ open, text, onClose, onSubmit }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ overflowY: "auto" }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          boxShadow: 1,
          p: 2,
          bgcolor: "background.paper",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          maxWidth: 500,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
          ລົບຮ້ານ <span style={{ color: "red" }}>{text}</span>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          <Button variant="contained" onClick={onSubmit}>
            ຍືນຍັນ
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
