import { Outlet } from "react-router-dom";
import Routes from "../constants/routes";
import StoreAddPage from "../pages/store/StoreAddPage";
import StoreDetailPage from "../pages/store/StoreDetailPage";
import StoreEditPage from "../pages/store/StoreEditPage";
import StoreListPage from "../pages/store/StoreListPage";

// eslint-disable-next-line
export default {
  path: Routes.STORE_PAGE,
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <StoreListPage />,
    },
    {
      path: "detail/:storeId",
      element: <StoreDetailPage />,
    },
    {
      path: "create",
      element: <StoreAddPage />,
    },
    {
      path: "update/:storeId",
      element: <StoreEditPage />,
    },
  ],
};
