import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { IoStorefront } from "react-icons/io5";
import { STORE_PAGE } from "../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import StoreForm from "../../components/form/StoreForm";
import { _Store, _StoreCreate } from "../../apis";
import { toast } from "react-toastify";
import UserForm from "../../components/form/UserForm";
import { _UserCreate } from "../../apis/Users.service";

export default function UserAddPage() {
  const params = useParams();
  const navigate = useNavigate();
  // state
  const [storeData, setStoreData] = useState();

  // function

  const handleCreateStore = async (value) => {
    const toastId = toast.loading("ກຳລັງບັນທຶກຮ້ານ...");
    const data = await _UserCreate({ ...value, storeId: params?.storeId });
    if (data?.err) {
      toast.update(toastId, {
        render: "ບໍ່ສຳເລັດ",
        type: "error",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render: `ເພີ່ມຜູ້ໃຊ້ ${data?.data?.name} ສຳເລັດ`,
        type: "success",
        isLoading: false,
        delay: 100,
        autoClose: 1000,
      });
      navigate("/" + STORE_PAGE + `/detail/${params?.storeId}`);
    }
  };

  useEffect(() => {
    (async () => {
      const data = await _Store(params?.storeId);
      if (!data?.err) {
        setStoreData(data?.data);
      }
    })();
  }, []);
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          color="inherit"
          onClick={() => navigate("/" + STORE_PAGE)}
        >
          <IoStorefront style={{ marginRight: 5 }} />
          ຈັດການຮ້ານ
        </Typography>

        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          color="text.primary"
        >
          {/* <GroupAddIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
          ເພີ່ມຜູ້ໃຊ້
        </Typography>
      </Breadcrumbs>
      <div>
        ເພີ່ມຜູ້ໃຊ້ ຮ້ານ: (
        <span style={{ color: "red" }}>{storeData?.name}</span>)
      </div>
      <div>
        <UserForm onSubmit={handleCreateStore} />
      </div>
    </div>
  );
}
