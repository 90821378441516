import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
} from "@mui/material";
// icons
import { ImFacebook2 } from "react-icons/im";
import { FcGoogle } from "react-icons/fc";
// sections
import LoginForm from "../components/form/LoginForm";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <RootStyle>

      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom>
          ແອັດມິນຈັດການຮ້ານ
          </Typography>

          <Typography variant="body2" sx={{ mb: 5 }}>
            ຖ້າທ່ານຍັງບໍ່ມີບັນຊີ? {""}
            <Link variant="subtitle2">ກົດທີ່ນີ້</Link>
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined">
              <ImFacebook2 style={{ width: 22, height: 22 }} />
            </Button>

            <Button fullWidth size="large" color="inherit" variant="outlined">
              <FcGoogle style={{ width: 22, height: 22 }} />
            </Button>
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>

          <LoginForm />
        </StyledContent>
      </Container>
    </RootStyle>
  );
}
