import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
// pages
import LoginPage from "../pages/LoginPage";

// Routes
import PrivateRoute from "./PrivateRoute";
import HomeRoutes from "./Home.routes";
import ProductsRoutes from "./Products.routes";
import SettingRoutes from "./Seeing.routes";
import StoreRoutes from "./Store.routes";
import UserRoutes from "./User.routes";
import AdsRoutes from "./Ads.routes";
// ------------------------ //

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        { path: "", element: <Navigate to="/store" /> },
        HomeRoutes,
        ProductsRoutes,
        SettingRoutes,
        StoreRoutes,
        UserRoutes,
        AdsRoutes
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "*",
      element: <h1>404 page</h1>,
    },
  ]);
}

export default Router;
