import React from "react";
import {
  FormGroup,
  FormLabel,
  TextField,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UploadImage from "../UploadImage";
import { Formik } from "formik";
import { IoMdClose } from "react-icons/io";

export default function UserForm({ value, onSubmit }) {
  const theme = useTheme();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...value,
        image: value?.image || "",
        name: value?.name || "",
        username: value?.username || "",
        status: value?.status || "ACTIVE", //["ACTIVE","BLOCKED"]
        role: value?.role || "STORE", //["ADMIN","STORE","STAFF"]
      }}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          await onSubmit(values);
          setSubmitting(false);
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <UploadImage
                src={values?.image}
                onChange={(e) => setFieldValue("image", e?.name)}
              />
            </div>
            <FormGroup>
              <FormLabel>ຊື່</FormLabel>
              <TextField
                error={errors.name}
                placeholder="ຊື່ຮ້ານ"
                name="name"
                value={values?.name}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ຊື່ຜູ້ໃຊ້</FormLabel>
              <TextField
                error={errors.username}
                placeholder="username"
                name="username"
                value={values?.username}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ລະຫັດຜ່ານ</FormLabel>
              <TextField
                error={errors.password}
                placeholder="password"
                name="password"
                value={values?.password}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>

            <FormControl fullWidth size="small">
              <InputLabel id="status">ສະຖານະ</InputLabel>
              <Select
                labelId="status"
                error={errors.status}
                value={values.status}
                name="status"
                label="ສະຖານະ"
                onChange={handleChange}
              >
                <MenuItem value={"ACTIVE"}>ໃຊ້ໄດ້</MenuItem>
                <MenuItem value={"BLOCKED"}>ປິດການໃຊ້ງານ</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="role">ສິດການເຂົ້າເຖິງ</InputLabel>
              <Select
                labelId="role"
                error={errors.role}
                value={values.role}
                name="role"
                label="ສິດການເຂົ້າເຖິງ"
                onChange={handleChange}
              >
                <MenuItem value={"ADMIN"} disabled>
                  ສິດແອັດມິນ
                </MenuItem>
                <MenuItem value={"STORE"}>ເຈົ້າຂອງຮ້ານ</MenuItem>
                <MenuItem value={"STAFF"}>ພະນັກງານ</MenuItem>
              </Select>
            </FormControl>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ boxShadow: 0 }}
              size="large"
            >
              ບັນທຶກ
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
