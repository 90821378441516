import React from "react";
import { Context } from "./Context";
import AuthState from "./globalState/auth.state";
import ProductsState from "./globalState/products.state";
import StoresState from "./globalState/stores.state";

export const StateProvider = ({ children }) => {
  const auth = AuthState();
  const products = ProductsState();
  const stores = StoresState();
  let store = Object.assign(auth, products, stores);
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
