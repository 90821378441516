import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  Button,
} from "@mui/material";
// import { LoadingButton } from '@mui/lab';

// icons
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// apis
import { _Login } from "../../apis";
import { useStore } from "../../providers";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { setToken } = useStore();

  // state
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    _Login({ username, password }).then((e) => {
      setToken(e?.data?.accessToken);
      navigate("/store", { replace: true });
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="username"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Box>
          <Checkbox name="remember" id="remember" />
          <label htmlFor="remember">ຈົດຈຳການເຂົ້າລະບົບ</label>
        </Box>
        <Link variant="subtitle2" underline="hover">
          ລືມລະຫັດຜ່ານ?
        </Link>
      </Stack>

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleLogin}
      >
        ເຂົ້າສູ່ລະບົບ
      </Button>
    </>
  );
}
