import { Outlet } from "react-router-dom";
import AdsList from "../pages/ads/AdsList.page";
import Routes from "../constants/routes";

// eslint-disable-next-line
export default {
  path: Routes.ADS_PAGE,
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <AdsList />,
    },
  ],
};
