import { Box, Typography, Card } from "@mui/material";
import React from "react";

export default function Homepage() {
  return (
    <Box>
      <Typography>Dashboad</Typography>
      <Card sx={{ p: 1, boxShadow: 2 }}>
        
      </Card>
    </Box>
  );
}
