import axios from "axios";
import { BACKEND_ENPOINT } from "../constants";
// --------------------- //

const _UsersAPI = BACKEND_ENPOINT + "/users";
const _UserAPI = BACKEND_ENPOINT + "/user";
const _UserCreateAPI = BACKEND_ENPOINT + "/user";
const _UserUpdateAPI = BACKEND_ENPOINT + "/user";
const _UserDeleteAPI = BACKEND_ENPOINT + "/user";

export async function _Users(queryParams) {
  try {
    const data = await axios.get(_UsersAPI + queryParams);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _User(_id) {
  try {
    const data = await axios.get(_UserAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _UserCreate(value) {
  try {
    const data = await axios.post(_UserCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _UserUpdate(_id, value) {
  try {
    const data = await axios.put(_UserUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _UserDelete(_id) {
  try {
    const data = await axios.delete(_UserDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
