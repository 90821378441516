import React, { useEffect } from "react";
import { _Stores } from "../../apis";
import { useStore } from "../../providers";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumbs, Link, useTheme, CircularProgress } from "@mui/material";
import { IoStorefront } from "react-icons/io5";
import { BiSearchAlt2 } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { Box } from "@mui/system";
// image
import imagePlaceholder from "../../images/image-placeholder.jpeg";
import { useNavigate } from "react-router-dom";
import { STORE_PAGE } from "../../constants/routes";
import { URL_PHOTO_AW3 } from "../../constants";

export default function StoreListPage() {
  const theme = useTheme();
  const { stores, setStores } = useStore();
  const { storesLoading, setStoresLoading } = useStore();
  const navigate = useNavigate();

  const notify = () => toast("Wow so easy!");
  const getData = async () => {
    setStoresLoading(true);
    const data = await _Stores();

    setStoresLoading(false);
    console.log("data", data);
    if (!data?.err) {
      setStores(data.data);
    }
  };
  useEffect(() => {
    const myPromise = getData();
    // toast.promise(myPromise, {
    //   pending: "Saving data...",
    //   success: { render: "Data saved.", delay: 100 },
    //   error: { render: "Data saving error.", delay: 100 },
    // });
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          //   href="#"
          //   onClick={() => navigate(-1)}
        >
          <IoStorefront style={{ marginRight: 5 }} />
          ຈັດການຮ້ານ
        </Link>

        {/* <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <GroupAddIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          ຕັ້ງຄ່າຜູ້ນຳໃຊ້
        </Typography> */}
      </Breadcrumbs>
      <div style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>ຮ້ານທັງໝົດ ({stores?.length})</div>
          <div
            style={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              padding: 5,
              borderRadius: 4,
              fontSize: 12,
            }}
            onClick={() => navigate(`/${STORE_PAGE}/create`)}
          >
            ເພີ່ມຮ້ານ
          </div>
        </div>
        <div style={{ display: "flex", gap: 5 }}>
          <input
            placeholder="search"
            style={{
              flexGrow: 1,
              borderRadius: 8,
              height: 36,
              border: 0,
              backgroundColor: "rgb(245,245,245)",
              padding: 10,
            }}
          />
          <button
            style={{
              width: 50,
              height: 36,
              borderRadius: 8,
              border: 0,
              padding: 10,
              backgroundColor: "rgb(216,216,216)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BiSearchAlt2 style={{ width: 18, height: 18, color: "black" }} />
          </button>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        {storesLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {stores?.map((e, i) => (
          <div
            style={{
              border: `1px solid ${theme.palette.primary.main}`,
              display: "flex",
              gap: 5,
            }}
            key={i}
          >
            <div
              style={{ width: 80, height: 80 }}
              onClick={() => navigate(`/${STORE_PAGE}/detail/${e?._id}`)}
            >
              <img
                src={
                  e?.image ? URL_PHOTO_AW3 + "/" + e?.image : imagePlaceholder
                }
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div
              style={{ flexGrow: 1 }}
              onClick={() => navigate(`/${STORE_PAGE}/detail/${e?._id}`)}
            >
              <div>ຮ້ານ: {e?.name}</div>
              <div>ສະຖານະ: {e?.status}</div>
            </div>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => navigate(`/${STORE_PAGE}/update/${e?._id}`)}
            >
              <FaEdit style={{ color: "white", width: 16, height: 16 }} />
            </div>
          </div>
        ))}
      </Box>
    </div>
  );
}
